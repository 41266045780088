<template>

  <div class="row" ref="memberForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-xs-6 col-md-6 col-sm-12">

                <ValidationProvider
                  vid="first_name"
                  rules="required"
                  name="First Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="First Name"
                            name="first_name"
                            fou
                            v-model="formData.first_name">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="last_name"
                  rules="required"
                  name="Last Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Last Name"
                            name="last_name"
                            fou
                            v-model="formData.last_name">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="company_id"
                  rules="required"
                  name="The Company"
                  v-slot="{ passed, failed,errors }">
                  <div class="form-group">
                    <label>Related Company</label>
                    <el-select class="select-default"
                               size="large"
                               v-model="formData.company_id"
                               label="company"
                               filterable
                               clearable
                               @change="getCompanyProjects()"
                               placeholder="Select Company">
                      <el-option v-for="option in companies"
                                 class="select-default"
                                 :value="option.id"
                                 :label="option.name"
                                 :key="option.id">
                      </el-option>
                    </el-select>

                    <div class="text-danger invalid-feedback" style="display: block;">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>

                <ValidationProvider
                  vid="role"
                  rules="required"
                  name="The Role"
                  v-slot="{ passed, failed,errors }">
                  <div class="form-group">
                    <label>Role</label>
                    <el-select class="select-default"
                               size="large"
                               v-model="formData.role"
                               label="role"
                               filterable
                               clearable
                               @change="getCompanyProjects()"
                               placeholder="Select Role">
                      <el-option v-for="option in roles"
                                 class="select-default"
                                 :value="option.label"
                                 :label="option.name"
                                 :key="option.label">
                      </el-option>
                    </el-select>

                    <div class="text-danger invalid-feedback" style="display: block;">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>

                <div class="form-group"
                     v-if="formData.company_id && formData.role !== 'ADMIN'">
                  <label>Projects</label>
                  <el-select class="select-info"
                             size="large"
                             v-model="formData.projects"
                             label="project"
                             filterable
                             multiple
                             placeholder="Select Project">
                    <el-option v-for="option in companyProjects"
                               class="select-default"
                               :value="option.id"
                               :label="option.title"
                               :key="option.id">
                    </el-option>
                  </el-select>
                </div>


                <ValidationProvider
                  vid="email"
                  rules="required|email"
                  name="Email"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="email"
                            :error="failed ? errors[0]: null"
                            label="Email"
                            name="email"
                            fou
                            v-model="formData.email">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="mobile"
                  rules="required"
                  name="Mobile"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="mobile"
                            :error="failed ? errors[0]: null"
                            label="Mobile"
                            name="mobile"
                            fou
                            v-model="formData.mobile">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  v-if="!editMode"
                  vid="password"
                  rules="required"
                  name="The Password"
                  v-slot="{ passed, failed,errors }"
                >
                  <fg-input type="password"
                            :error="failed ? errors[0]: null"
                            :hasSuccess="passed"
                            label="Password"
                            v-model="formData.password">
                  </fg-input>
                </ValidationProvider>

                <div class="form-group">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>

              </div>
              <div class="col-xs-6 col-md-6 col-sm-12">
                <avatar-uploader
                  :path=formData.profile_image
                  @imageUploaded="handleImage($event)"
                ></avatar-uploader>
              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>&nbsp;
            <l-button @click="$router.push('/members/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option} from 'element-ui';
import {Switch as LSwitch} from 'src/components/index'
import {AvatarUploader as AvatarUploader} from 'src/components/index'


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    LSwitch,
    AvatarUploader
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      companies: [],
      roles: [],
      companyProjects: [],

      formData: {
        first_name: "",
        last_name: "",
        company_id: "",
        role: "",
        projects: [],
        email: "",
        mobile: "",
        profile_image: "",
        is_active: true,
        password: ""
      },

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.memberForm
    });

    this.axios.get("members/builder").then((response) => {
      this.companies = response.data.companies;
      this.roles = response.data.roles;
      this.id = this.$route.params['id'];
      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Member";
        this.getMember();
      } else {
        this.editMode = false;
        this.formTitle = "Add Member";
        this.loader.hide();
      }
    }).catch((error) => {
      console.error(error);
    })


  },


  methods: {
    getMember() {
      this.axios.get("members/get/" + this.id).then((response) => {
        this.formData = response.data;
        this.formData.projects = response.data.projects.map(p => {
          return p.id
        });
        this.getCompanyProjects(true);
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Member Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        let updateData = {...this.formData};
        this.$delete(updateData, 'password');
        request = this.axios.put("members/update/" + this.id, updateData);
        successMessage = "Member Updated Successfully";
      } else {
        request = this.axios.post("members/create", this.formData);
        successMessage = "Member Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/members/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      });
    },

    handleImage(e) {
      this.formData.profile_image = e;
    },

    getCompanyProjects(initialInvokation) {
      if (!initialInvokation) {
        this.formData.projects = null;
      }
      if (this.formData.role && this.formData.role !== 'ADMIN' && this.formData.company_id) {
        this.axios.get("companies/" + this.formData.company_id + "/projects").then((response) => {
          this.companyProjects = response.data.projects;
        }).catch((error) => {
        });
      }
    }

  }
}
</script>

<style>
</style>
